import { CirclePops } from "./model";

export function Circle({step_number,step_name,completed, is_last}:CirclePops){
      const circleColor = completed? "bg#highlight": "bg#n-50"
      return(
            <div id="main-circle-layout" class={`flex flex-col  ${is_last ?"items-start":"items-center" } justify-center gap-25px my-20px`}>
           
           <div class="flex items-center justify-center">
            <div class={`flex items-center justify-center w-8 h-8 rounded-full  ${circleColor} text-white gap-25px`}>
            <span>{step_number}</span>
            </div>
           {!is_last &&<hr class={` w-100px h-1 ${circleColor}`}/>
           } 
           
            </div>
            <p class={`text-14px ${completed && "underline"} w-full`}>{step_name}</p>
            
            </div>
      )
}